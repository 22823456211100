// This is a manifest file that'll be compiled into application.js, which will include all the files
// listed below.
//
// Any JavaScript/Coffee file within this directory, lib/assets/javascripts, or any plugin's
// vendor/assets/javascripts directory can be referenced here using a relative path.
//
// It's not advisable to add code directly here, but if you do, it'll appear at the bottom of the
// compiled file. JavaScript code in this file should be added after the last require_* statement.
//
// Read Sprockets README (https://github.com/rails/sprockets#sprockets-directives) for details
// about supported directives.
//
import * as bootstrap from 'bootstrap';
import "@fortawesome/fontawesome-free/js/all";
require("@rails/ujs").start()
require("turbolinks").start()
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import './subscriptions';

let scrollTriggers = [];

$( document ).on('turbolinks:load', function() {

  scrollTriggers.forEach(scrollTrigger => {
    scrollTrigger.kill();
  });
  scrollTriggers = [];

  if($('body').is('.web') && $('body').is('.index')) {
    gsap.registerPlugin(ScrollTrigger);
    gsap.fromTo('.right-banner', {x: '100%'}, {x: '0%', duration: 1, ease: "power2.inOut", delay: 0.5, scrollTrigger: '.right-banner'});
    gsap.from('.callout', {opacity: 0, duration: 0.3, ease: "power2.inOut", delay: 0.3, scrollTrigger: '.callout'});
    gsap.from('.bullet', {opacity: 0, y:100, duration: 0.5, ease: "power2.inOut", delay: 0.5, scrollTrigger: '.bullet'});
  
    gsap.from('.payment-emissions', {opacity: 0, y:100, duration: 0.5, ease: "power2.inOut", delay: 0.3, scrollTrigger: '.impact-section'});
    gsap.from('.payment-volume', {opacity: 0, y:100, duration: 0.5, ease: "power2.inOut", delay: 0.5, scrollTrigger: '.impact-section'});
    gsap.from('.emissions-second', {opacity: 0, y:100, duration: 0.5, ease: "power2.inOut", delay: 0.7, scrollTrigger: '.impact-section'});
  
    scrollTriggers.push(
      ScrollTrigger.create({
        trigger: '.impact-section',
        start: 'top bottom',
        once: true,
        onEnter: function() {
          $('.count').each(function () {
            let $this = $(this);
            jQuery({ Counter: 0 }).animate({ Counter: $(this).text() }, {
              duration: 2000,
              easing: 'swing',
              step: function () {
                $this.text(Math.ceil(this.Counter));
              }
            });
          });
        }
      })
    );

    // let t1 = gsap.to('.navbar-brand', 0.75, {text:"stabiliti:together", color:'#F8F9FA', ease:"none", paused:true, reversed:true});



    scrollTriggers.push(
      ScrollTrigger.create({
        trigger: '.body-content',
        start: 4,
        onEnter: () => {;
          gsap.to(".path-logo", { fill: "#F8F9FA", duration: 0.25});
          gsap.to('.menubar', { duration: 0.25, backgroundColor: 'rgba(27,54,82,1.0)'});
          gsap.to(".nav-link", {color:"#F8F9FA", duration: 0.25});
          gsap.to(".dropdown-toggle", {className: "nav-link dropdown-toggle light"});
          gsap.to(".dropdown-toggle.show", {color:"#F8F9FA", duration: 0.25});
          gsap.to('.menubar', { duration: 0.25, y: -32, delay: 0.1});
        },
        onLeaveBack: () => {
          gsap.to('.menubar', { duration: 0.25, y: 0});
          gsap.to(".path-logo", { fill: "#212121", duration: 0.25, delay: 0.1});
          gsap.to('.menubar', { duration: 0.25, backgroundColor: 'rgba(27,54,82,0.0)', delay: 0.1});
          gsap.to(".nav-link", {color:"#212121", duration: 0.25, delay: 0.1});
          gsap.to(".dropdown-toggle", {className: "nav-link dropdown-toggle"});
          gsap.set(".dropdown-toggle.show", {color:"#212121", duration: 0.25, delay: 0.1});
        },
      })
    );

    gsap.from('.capital', {opacity: 0, y:100, duration: 0.5, ease: "power2.inOut", delay: 0.5, scrollTrigger: '.capital'});
  
    scrollTriggers.push(
      ScrollTrigger.create({
        trigger: '.capital',
        start: 'top bottom',
        once: true,
        onEnter: function() {
          $('.capital-count').each(function () {
            let $this = $(this);
            jQuery({ Counter: 0 }).animate({ Counter: $(this).text() }, {
              duration: 2000,
              easing: 'swing',
              step: function () {
                $this.text(Math.ceil(this.Counter));
              }
            });
          });
        }
      })
    );
  
    let maxHeight = 0;
    $('.quote-block').each(function(){
      maxHeight = ($(this).height() > maxHeight ? $(this).height() : maxHeight);
    });
    $('.quote-container').height(maxHeight + 200); 
  } else {
    gsap.registerPlugin(ScrollTrigger);
    scrollTriggers.push(
      ScrollTrigger.create({
        trigger: '.body-content',
        start: 4,
        onEnter: () => {
          gsap.to('.menubar-light', { duration: 0.25, y: -30})
        },
        onLeaveBack: () => {
          gsap.to('.menubar-light', { duration: 0.25, y: 0})
        },
      })
    );
  }
  
  if($('body').is('.web') && $('body').is('.privacy_policy')) {
    $('table').addClass('table table-striped');
  };

});