//
// Footer subscription form functions
//

import './jquery.validate.min';

$( document ).on('turbolinks:load', function() {
  $('#subscription_form').validate({
    rules: {
      subscription_name: {
        required: true,
        minlength: 6,
        maxlength: 30
      },
      subscription_email: {
        required: true,
        email: true,
        minlength: 5,
        maxlength: 320
      }
    },
    messages: {
      subscription_name: {
        required: "Name is required",
        minlength: "Name needs at least 6 characters",
        maxlength: "Name maximum 30 characters"
      },
      subscription_email: {
        required: "Email is required",
        minlength: "Please enter a valid email address",
        maxlength: "Max limit for email"
      }
    },
    errorElement : 'div',
    errorLabelContainer: '.error-msg'
  });
  
  $('#subscribe-btn').click(function() {
    if($('#subscription_form').valid()) {
      $.ajax({
        url: 'subscriptions',
        data: { authenticity_token: $('[name="csrf-token"]')[0].content, subscription: {email: $('#subscription_email').val()}},
        method: 'POST',
        success: function(res) {
          $('#subscription_form').html('<p class="lead">Thanks for subscribing</p>');
        }
      }); 
    }
  });
});